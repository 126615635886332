import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { Header, Footer } from './components';
import Pages from './components/Pages';

function App() {
    return (
        <Router>
            <div className="App">
                <Header />
                <Pages />
                <Footer />
            </div>
        </Router>
    );
}

export default App;
