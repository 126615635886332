import { axiosClient } from "./client-axios";

// const domain = "http://localhost:5000";
const domain = "https://vinhnguyen.gcalls.vn";

export const ApiCategory = {
    getAll: () => {
        const url = `${domain}/categories`;
        return axiosClient.get(url);
    }
};
