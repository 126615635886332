import { axiosClient } from "./client-axios";

// const domain = "http://localhost:5000";
const domain = "https://vinhnguyen.gcalls.vn";

export const ApiProduct = {
    getAll: () => {
        const url = `${domain}/products`;
        return axiosClient.get(url);
    },

    getById: (id) => {
        const url = `${domain}/products/${id}`;
        return axiosClient.get(url);
    },

    getByCategory: (id) => {
        const url = `${domain}/products/category/${id}`;
        return axiosClient.get(url);
    },
};
