import React from "react";
import { Route, Routes } from "react-router-dom";
import { CategoryPage } from "./mainpages/CategoryPage";
import { ProductDetail } from "./mainpages/ProductDetail";
import { MainPages } from "./mainpages/MainPages";
import { Cart } from "./mainpages/Cart";
import { Checkout } from "./mainpages/Checkout";
import { CallBack } from "./mainpages/CallBack";
import { SearchOrders } from "./mainpages/SearchOrders";
import { Order } from "./mainpages/Order";
import { NotFound } from "./NotFound";

const Pages = () => {
    return (
        <Routes>
            <Route path="/" element={<MainPages />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/category" element={<CategoryPage />} />
            <Route path="/category/:id" element={<CategoryPage />} />
            <Route path="/products/:id" element={<ProductDetail />} />
            <Route path="/callback" element={<CallBack />} />
            <Route path="/orders" element={<SearchOrders />} />
            <Route path="/order/:id" element={<Order />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
}

export default Pages;