import { axiosClient } from "./client-axios";

// const domain = "http://localhost:5000";
const domain = "https://vinhnguyen.gcalls.vn";

export const ApiOrder = {
    getAll: () => {
        const url = `${domain}/orders`;
        return axiosClient.get(url);
    },

    get: (id) => {
        const url = `${domain}/orders/${id}`;
        return axiosClient.get(url);
    },

    add: (order) => {
        const url = `${domain}/orders/create`;
        return axiosClient.post(url, order);
    }
};